<template>
    <div>
        <template v-if="updateMode && !listMode">
            <v-btn :loading="loading.url" small class="ml-2 lighten-2" @click.stop="printDebitNote()">
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.pdf') }}</span>
            </v-btn>
            <v-btn :loading="loading.urlNoTax" small class="ml-2 lighten-2" @click.stop="printDebitNote(true)">
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ currency + ' ' + $t('message.only') }}</span>
            </v-btn>
        </template>
        <template v-if="updateMode && listMode">
            <v-list-item class="font-sm" @click.stop="printDebitNote()">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.url"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.pdf') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printDebitNote(true)">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.urlNoTax"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.usdOnly') }}</span>
            </v-list-item>
        </template>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
// import {log, makeId} from "Helpers/helpers";

export default {
    name: "PrintDebitNoteButton",
    props: ['debitNoteId','updateMode','listMode','taxMode','currency'],
    data() {
        return {
            loading: {
                url: false,
                urlNoTax: false
            },
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getPrintPdfUrl(noTax = false) {
            return new Promise((resolve, reject) => {
                let urlComponent = "/print-url/dn/"
                if(noTax) urlComponent = "/print-url/dn-no-tax/"
                api
                    .get(urlComponent + this.debitNoteId)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        printDebitNote(noTax = false) {
            if(noTax){
                this.loading.urlNoTax = true;
            } else {
                this.loading.url = true;
            }
            this.getPrintPdfUrl(noTax)
                .then(response => {
                    if(response.data.status === 'success') {
                        let tab = window.open(response.data.url, '_blank');
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                    }
                    if(noTax){
                        this.loading.urlNoTax = false;
                    } else {
                        this.loading.url = false;
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.pdfError'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        })
                    if(noTax){
                        this.loading.urlNoTax = false;
                    } else {
                        this.loading.url = false;
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>